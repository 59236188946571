import gql from 'graphql-tag'

export const GET_MY_TUTOR_TOKEN = gql`
  query GetMyTutorToken(
    $userId: String!
    $email: String!
    $accountExpiresAt: String!
    $userName: String!
    $userType: String!
  ) {
    getMyTutorToken(
      myTutorTokenInput: {
        userId: $userId
        email: $email
        accountExpiresAt: $accountExpiresAt
        userName: $userName
        userType: $userType
      }
    ) {
      token
      expiry
      link
    }
  }
`
