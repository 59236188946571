import Spacer from '@berlitz/spacer'
import Button from '@components/Button'
import { Lesson } from '@components/LPCard'
import { Icon, IconCircle, LinkButton, Tag } from '@components/LPCard/Common'
import ActivityRounds from '@components/LPCard/Common/ActivityRounds'
import {
  CleanHeader,
  LessonDesc,
  LessonWrapper,
  TagContainer,
  Lesson0Wrapper,
  Lesson0Desc,
  AIWrapper,
  Subtitle,
} from '@components/LPCard/Lesson/style'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IPInProgress } from './interface'
import Compass1 from '@berlitz/streamline-icons/lib/regular/48-Maps-Navigation/01-Compass/Compass1'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import AITutorLink from '../Common/AITutorLink'

const InProgress: React.FC<IPInProgress> = ({ title, fullname, activities = [], link, unit }) => {
  const isLessonZero = unit?.IsLessonZero
  const cardTitle = (fullname || '').trim()
  const [modalOpen, setModalOpen] = React.useState(false)
  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })

  return isLessonZero ? (
    <Lesson
      status="in-progress"
      title={cardTitle}
      isLessonZero={isLessonZero}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      language={unit?.StudentProgram?.program?.Language}
    >
      <Lesson0Wrapper>
        <CleanHeader>
          <Icon color="blue100">
            <IconCircle color="white" />
            <Compass1 />
          </Icon>
        </CleanHeader>
        <Spacer size="xxs" />
        <TagContainer>
          <Tag>vvvvv{title}</Tag>
          <Tag>
            <FormattedMessage id="In-progress" />
          </Tag>
        </TagContainer>
        <Spacer size="xs" />
        <div>
          <Lesson0Desc>
            <FormattedMessage
              id="Continue with {cardTitle} now"
              defaultMessage="Continue with {cardTitle} now"
              values={{ cardTitle }}
            />
          </Lesson0Desc>
        </div>
        <Spacer size="xs" />
        <LinkButton onClick={() => setModalOpen(true)}>
          <FormattedMessage id="What is {cardTitle}?" defaultMessage="What is {cardTitle}?" values={{ cardTitle }} />
        </LinkButton>
        <Spacer size="md" />
        <div>
          {!unit?.HasContractEnded && (
            <Button
              color="success"
              disabled={!link}
              narrow
              onClick={() => {
                if (state.allowed) {
                  return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', link, activities)
                }

                link && window.open(link, '_self')
              }}
            >
              <FormattedMessage id="Continue" />
            </Button>
          )}
          <Spacer size="lg" />
        </div>

        <AITutorLink unit={unit} status="locked" txtColor="white" btnColor="brandSecondary" />
      </Lesson0Wrapper>
    </Lesson>
  ) : (
    <Lesson status="in-progress" title={title} isFromElective={unit?.IsFromElective}>
      <LessonWrapper>
        <TagContainer className="status-tag">
          <Tag>{title}</Tag>
          <Tag>
            <FormattedMessage id="In-progress" />
          </Tag>
        </TagContainer>
        <div>
          <LessonDesc>{cardTitle}</LessonDesc>
        </div>
        <ActivityRounds activities={activities} unit={unit} disabled={unit?.HasContractEnded} />
        <div>
          {!unit?.HasContractEnded && (
            <Button
              disabled={!link}
              narrow
              onClick={() => {
                if (state.allowed) {
                  return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', link, activities)
                }

                link && window.open(link, '_self')
              }}
            >
              <FormattedMessage id="Continue" />
            </Button>
          )}
          <Spacer size="xxl" />
        </div>
      </LessonWrapper>

      <AITutorLink unit={unit} status="locked" txtColor="black" btnColor="brandPrimary" />
    </Lesson>
  )
}

export default InProgress
